import React, { useState } from 'react';
import { ArrowUpRight, ArrowDownRight, ChevronDown } from 'lucide-react';
import { format } from 'date-fns';

interface Props {
  title: string;
  value: string;
  change: string;
  trend: 'up' | 'down';
  icon: React.ElementType;
  data?: {
    date: string;
    value: number;
  }[];
}

export default function StatCard({ title, value, change, trend, icon: Icon, data }: Props) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="bg-white p-6 rounded-lg border border-gray-200">
      <div className="flex items-center justify-between">
        <div className="p-2 bg-blue-50 rounded-lg">
          <Icon className="h-6 w-6 text-blue-600" />
        </div>
        <div className="flex items-center">
          <span className={`text-sm font-medium flex items-center ${
            trend === 'up' ? 'text-green-600' : 'text-red-600'
          }`}>
            {change}
            {trend === 'up' ? (
              <ArrowUpRight className="h-4 w-4 ml-1" />
            ) : (
              <ArrowDownRight className="h-4 w-4 ml-1" />
            )}
          </span>
          {data && (
            <button
              onClick={() => setIsExpanded(!isExpanded)}
              className="ml-2 p-1 hover:bg-gray-100 rounded-full"
            >
              <ChevronDown className={`h-4 w-4 transform transition-transform ${
                isExpanded ? 'rotate-180' : ''
              }`} />
            </button>
          )}
        </div>
      </div>
      <p className="mt-4 text-2xl font-semibold text-gray-900">{value}</p>
      <p className="mt-1 text-sm text-gray-500">{title}</p>

      {isExpanded && data && (
        <div className="mt-4 pt-4 border-t border-gray-200">
          <table className="min-w-full">
            <thead>
              <tr>
                <th className="text-left text-xs font-medium text-gray-500">Date</th>
                <th className="text-right text-xs font-medium text-gray-500">Value</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index} className="hover:bg-gray-50">
                  <td className="py-2 text-sm text-gray-900">
                    {format(new Date(item.date), 'MMM d, yyyy')}
                  </td>
                  <td className="py-2 text-sm text-gray-900 text-right">
                    {item.value}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}