import React from 'react';
import { Gift, Award, TrendingUp, Users } from 'lucide-react';

export default function Rewards() {
  const rewards = [
    {
      id: 1,
      title: 'Silver Referrer',
      points: 500,
      description: 'Achieve 10 successful referrals',
      progress: 60,
      icon: Users
    },
    {
      id: 2,
      title: 'Education Master',
      points: 1000,
      description: 'Complete 5 educational courses',
      progress: 40,
      icon: Award
    },
    {
      id: 3,
      title: 'Communication Pro',
      points: 750,
      description: 'Maintain 95% response rate',
      progress: 80,
      icon: TrendingUp
    }
  ];

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold text-gray-900">Rewards Program</h1>
        <button className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700">
          <Gift className="h-4 w-4 mr-2" />
          Redeem Points
        </button>
      </div>

      <div className="bg-gradient-to-r from-blue-600 to-blue-800 rounded-lg text-white p-6">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-lg font-medium">Available Points</p>
            <p className="text-4xl font-bold mt-1">2,450</p>
          </div>
          <Award className="h-16 w-16 opacity-50" />
        </div>
        <div className="mt-4">
          <div className="flex items-center justify-between text-sm">
            <span>Next Reward at 3,000 points</span>
            <span>82%</span>
          </div>
          <div className="w-full bg-white bg-opacity-20 rounded-full h-2 mt-2">
            <div
              className="bg-white rounded-full h-2"
              style={{ width: '82%' }}
            ></div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {rewards.map((reward) => {
          const Icon = reward.icon;
          return (
            <div key={reward.id} className="bg-white rounded-lg border border-gray-200 p-6">
              <div className="flex items-center justify-between">
                <div className="p-3 bg-blue-50 rounded-lg">
                  <Icon className="h-6 w-6 text-blue-600" />
                </div>
                <span className="text-sm font-medium text-blue-600">{reward.points} points</span>
              </div>
              <h3 className="mt-4 text-lg font-semibold text-gray-900">{reward.title}</h3>
              <p className="mt-1 text-sm text-gray-500">{reward.description}</p>
              <div className="mt-4">
                <div className="flex items-center justify-between text-sm text-gray-500 mb-1">
                  <span>Progress</span>
                  <span>{reward.progress}%</span>
                </div>
                <div className="w-full bg-gray-200 rounded-full h-2">
                  <div
                    className="bg-blue-600 h-2 rounded-full"
                    style={{ width: `${reward.progress}%` }}
                  ></div>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="bg-white rounded-lg border border-gray-200">
        <div className="p-6 border-b border-gray-200">
          <h2 className="text-lg font-semibold text-gray-900">Recent Activity</h2>
        </div>
        <div className="p-6">
          <div className="space-y-4">
            {[1, 2, 3].map((i) => (
              <div key={i} className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                <div className="flex items-center space-x-4">
                  <div className="h-10 w-10 rounded-full bg-blue-100 flex items-center justify-center">
                    <Gift className="h-5 w-5 text-blue-600" />
                  </div>
                  <div>
                    <p className="font-medium text-gray-900">Earned points for referral</p>
                    <p className="text-sm text-gray-500">Patient: John Doe</p>
                  </div>
                </div>
                <div className="text-right">
                  <p className="font-medium text-green-600">+100 points</p>
                  <p className="text-sm text-gray-500">2 hours ago</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}