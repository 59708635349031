import React from 'react';
import { X, Mail, Phone, MapPin, Calendar, FileText, Clock } from 'lucide-react';

interface ContactViewerProps {
  type: 'dentist' | 'specialist' | 'patient';
  data: any;
  onClose: () => void;
}

export default function ContactViewer({ type, data, onClose }: ContactViewerProps) {
  const renderPatientDetails = () => (
    <>
      <div className="grid grid-cols-2 gap-6">
        <div>
          <h3 className="text-sm font-medium text-gray-500">Date of Birth</h3>
          <p className="mt-1 text-sm text-gray-900">{data.dateOfBirth}</p>
        </div>
        <div>
          <h3 className="text-sm font-medium text-gray-500">Insurance</h3>
          <p className="mt-1 text-sm text-gray-900">{data.insurance}</p>
        </div>
      </div>
      <div className="mt-6">
        <h3 className="text-sm font-medium text-gray-500">Medical History</h3>
        <p className="mt-1 text-sm text-gray-900">{data.medicalHistory}</p>
      </div>
    </>
  );

  const renderProviderDetails = () => (
    <>
      <div className="grid grid-cols-2 gap-6">
        <div>
          <h3 className="text-sm font-medium text-gray-500">Practice</h3>
          <p className="mt-1 text-sm text-gray-900">{data.practice}</p>
        </div>
        <div>
          <h3 className="text-sm font-medium text-gray-500">Specialty</h3>
          <p className="mt-1 text-sm text-gray-900">{data.specialty}</p>
        </div>
      </div>
      <div className="mt-6">
        <h3 className="text-sm font-medium text-gray-500">Preferences</h3>
        <p className="mt-1 text-sm text-gray-900">{data.preferences}</p>
      </div>
    </>
  );

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl max-w-2xl w-full">
        <div className="flex items-center justify-between p-6 border-b border-gray-200">
          <h2 className="text-xl font-semibold text-gray-900">Contact Details</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
            <X className="h-6 w-6" />
          </button>
        </div>

        <div className="p-6">
          <div className="flex items-center space-x-4 mb-6">
            <div className="h-16 w-16 rounded-full bg-blue-100 flex items-center justify-center">
              <span className="text-xl font-medium text-blue-600">
                {data.name.split(' ').map((n: string) => n[0]).join('')}
              </span>
            </div>
            <div>
              <h3 className="text-lg font-medium text-gray-900">{data.name}</h3>
              <p className="text-sm text-gray-500">
                {type.charAt(0).toUpperCase() + type.slice(1)}
              </p>
            </div>
          </div>

          <div className="space-y-4 mb-6">
            <div className="flex items-center text-sm text-gray-500">
              <Mail className="h-5 w-5 mr-2" />
              {data.email}
            </div>
            <div className="flex items-center text-sm text-gray-500">
              <Phone className="h-5 w-5 mr-2" />
              {data.phone}
            </div>
            <div className="flex items-center text-sm text-gray-500">
              <MapPin className="h-5 w-5 mr-2" />
              {data.address}
            </div>
          </div>

          {type === 'patient' ? renderPatientDetails() : renderProviderDetails()}

          <div className="mt-6 border-t border-gray-200 pt-6">
            <h3 className="text-sm font-medium text-gray-500 mb-4">Recent Activity</h3>
            <div className="space-y-3">
              {[1, 2, 3].map((i) => (
                <div key={i} className="flex items-center text-sm">
                  <Clock className="h-4 w-4 text-gray-400 mr-2" />
                  <span className="text-gray-900">Activity {i}</span>
                  <span className="text-gray-500 ml-auto">2h ago</span>
                </div>
              ))}
            </div>
          </div>

          <div className="mt-6 flex justify-end space-x-3">
            <button className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50">
              <FileText className="h-4 w-4 inline mr-2" />
              View Records
            </button>
            <button className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700">
              <Calendar className="h-4 w-4 inline mr-2" />
              Schedule
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}