import React, { useCallback } from 'react';
import { Upload } from 'lucide-react';

interface Props {
  onSuccess: () => void;
  onError: (error: string) => void;
}

export default function CSVUpload({ onSuccess, onError }: Props) {
  const handleFileUpload = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        // Simulated CSV processing
        console.log('Processing CSV:', e.target?.result);
        onSuccess();
      } catch (error: any) {
        onError(error.message || 'Failed to import providers');
      }
    };
    reader.readAsText(file);
  }, [onSuccess, onError]);

  return (
    <div className="mt-4">
      <div className="flex items-center justify-center w-full">
        <label className="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100">
          <div className="flex flex-col items-center justify-center pt-5 pb-6">
            <Upload className="w-8 h-8 mb-2 text-gray-500" />
            <p className="mb-2 text-sm text-gray-500">
              <span className="font-semibold">Click to upload</span> or drag and drop
            </p>
            <p className="text-xs text-gray-500">CSV file with provider data</p>
          </div>
          <input
            type="file"
            className="hidden"
            accept=".csv"
            onChange={handleFileUpload}
          />
        </label>
      </div>
      
      <div className="mt-4">
        <h4 className="text-sm font-medium text-gray-900">CSV Format Requirements:</h4>
        <p className="mt-1 text-sm text-gray-500">
          The CSV file should include the following columns: Name, Email, Phone, Address, Practice, Specialty, Notes, Preferences, Type (optional)
        </p>
        <a
          href="#"
          className="mt-2 inline-flex items-center text-sm text-blue-600 hover:text-blue-500"
        >
          Download template
        </a>
      </div>
    </div>
  );
}