import React, { useEffect, useRef } from 'react';
import { Calendar, MessageSquare, UserPlus, Clock } from 'lucide-react';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { useAlertStore, useAuthStore } from '../lib/store';

interface Props {
  onClose: () => void;
}

interface Alert {
  id: string;
  type: 'appointment' | 'message' | 'referral';
  title: string;
  description: string;
  time: Date;
  read: boolean;
}

export default function AlertsDropdown({ onClose }: Props) {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const alerts = useAlertStore((state) => state.alerts);
  const markAsRead = useAlertStore((state) => state.markAsRead);
  const markAllAsRead = useAlertStore((state) => state.markAllAsRead);
  const user = useAuthStore((state) => state.user);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        onClose();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]);

  const getIcon = (type: Alert['type']) => {
    switch (type) {
      case 'appointment':
        return <Calendar className="h-5 w-5 text-blue-500" />;
      case 'message':
        return <MessageSquare className="h-5 w-5 text-green-500" />;
      case 'referral':
        return <UserPlus className="h-5 w-5 text-purple-500" />;
    }
  };

  const handleAlertClick = (alert: Alert) => {
    markAsRead(alert.id);

    switch (alert.type) {
      case 'appointment':
        navigate('/schedule');
        break;
      case 'message':
        navigate('/communications');
        break;
      case 'referral':
        navigate('/specialists');
        break;
    }
    onClose();
  };

  const handleMarkAllAsRead = () => {
    if (!user) return;
    markAllAsRead();
  };

  const unreadCount = alerts.filter(alert => !alert.read).length;

  return (
    <div 
      ref={dropdownRef}
      className="absolute right-0 mt-2 w-80 bg-white rounded-lg shadow-lg border border-gray-200 z-50"
    >
      <div className="p-4 border-b border-gray-200">
        <div className="flex items-center justify-between">
          <h3 className="text-lg font-semibold text-gray-900">
            Notifications {unreadCount > 0 && `(${unreadCount})`}
          </h3>
          <button 
            onClick={handleMarkAllAsRead}
            className="text-sm text-blue-600 hover:text-blue-700"
          >
            Mark all as read
          </button>
        </div>
      </div>

      <div className="max-h-96 overflow-y-auto">
        {alerts.length === 0 ? (
          <div className="p-4 text-center text-gray-500">
            No notifications
          </div>
        ) : (
          alerts.map((alert) => (
            <div
              key={alert.id}
              onClick={() => handleAlertClick(alert)}
              className={`p-4 hover:bg-gray-50 cursor-pointer ${
                !alert.read ? 'bg-blue-50' : ''
              }`}
            >
              <div className="flex items-start space-x-3">
                <div className="flex-shrink-0">
                  {getIcon(alert.type as Alert['type'])}
                </div>
                <div className="flex-1 min-w-0">
                  <p className="text-sm font-medium text-gray-900">
                    {alert.title}
                  </p>
                  <p className="text-sm text-gray-500">
                    {alert.description}
                  </p>
                  <div className="mt-1 flex items-center text-xs text-gray-500">
                    <Clock className="h-3 w-3 mr-1" />
                    {format(new Date(alert.time), 'h:mm a')}
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>

      <div className="p-4 border-t border-gray-200">
        <button
          onClick={() => {
            navigate('/notifications');
            onClose();
          }}
          className="w-full text-center text-sm text-blue-600 hover:text-blue-700"
        >
          View all notifications
        </button>
      </div>
    </div>
  );
}