import React, { useState } from 'react';
import { UserPlus, Building2, Upload } from 'lucide-react';
import NewProviderForm from '../components/forms/NewProviderForm';
import NewPatientForm from '../components/forms/NewPatientForm';
import CSVUpload from '../components/CSVUpload';

export default function Admin() {
  const [showProviderForm, setShowProviderForm] = useState(false);
  const [showPatientForm, setShowPatientForm] = useState(false);
  const [showCSVUpload, setShowCSVUpload] = useState(false);
  const [notification, setNotification] = useState<{ type: 'success' | 'error'; message: string } | null>(null);

  const handleUploadSuccess = () => {
    setNotification({
      type: 'success',
      message: 'Providers imported successfully',
    });
    setShowCSVUpload(false);
  };

  const handleUploadError = (error: string) => {
    setNotification({
      type: 'error',
      message: error,
    });
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold text-gray-900">Admin Dashboard</h1>
      </div>

      {notification && (
        <div
          className={`p-4 rounded-md ${
            notification.type === 'success' ? 'bg-green-50 text-green-800' : 'bg-red-50 text-red-800'
          }`}
        >
          {notification.message}
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-white p-6 rounded-lg border border-gray-200">
          <h2 className="text-lg font-semibold text-gray-900 mb-4">Provider Management</h2>
          <div className="space-y-4">
            <button
              onClick={() => setShowProviderForm(true)}
              className="w-full flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700"
            >
              <UserPlus className="h-4 w-4 mr-2" />
              Add New Provider
            </button>
            <button
              onClick={() => setShowCSVUpload(true)}
              className="w-full flex items-center justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
            >
              <Upload className="h-4 w-4 mr-2" />
              Import Providers (CSV)
            </button>
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg border border-gray-200">
          <h2 className="text-lg font-semibold text-gray-900 mb-4">Patient Management</h2>
          <div className="space-y-4">
            <button
              onClick={() => setShowPatientForm(true)}
              className="w-full flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700"
            >
              <Building2 className="h-4 w-4 mr-2" />
              Add New Patient
            </button>
          </div>
        </div>
      </div>

      {showProviderForm && (
        <NewProviderForm onClose={() => setShowProviderForm(false)} />
      )}

      {showPatientForm && (
        <NewPatientForm onClose={() => setShowPatientForm(false)} />
      )}

      {showCSVUpload && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg shadow-xl max-w-2xl w-full">
            <div className="p-6">
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Import Providers</h2>
              <CSVUpload
                onSuccess={handleUploadSuccess}
                onError={handleUploadError}
              />
              <div className="mt-6 flex justify-end">
                <button
                  onClick={() => setShowCSVUpload(false)}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}