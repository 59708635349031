import React from 'react';
import { NavLink } from 'react-router-dom';
import { 
  LayoutDashboard, 
  Users, 
  MessageCircle, 
  GraduationCap, 
  Calendar, 
  FileText, 
  Gift,
  Settings,
  Stethoscope,
  UserCircle,
  Building2,
  HeartPulse,
  MessageSquare
} from 'lucide-react';

export default function Sidebar() {
  const mainMenu = [
    { icon: LayoutDashboard, label: 'Dashboard', path: '/' },
    { icon: MessageCircle, label: 'Communications', path: '/communications' },
    { icon: MessageCircle, label: 'Forum', path: '/forum' },
    { icon: Calendar, label: 'Schedule', path: '/schedule' },
  ];

  const providerMenu = [
    { icon: HeartPulse, label: 'Specialists', path: '/specialists' },
    { icon: UserCircle, label: 'General Dentists', path: '/dentists' },
    { icon: Users, label: 'Patients', path: '/patients' },
  ];

  const additionalMenu = [
    { icon: GraduationCap, label: 'Education', path: '/education' },
    { icon: FileText, label: 'Documents', path: '/documents' },
    { icon: Gift, label: 'Rewards', path: '/rewards' },
    { icon: Settings, label: 'Settings', path: '/settings' },
    { icon: Settings, label: 'Admin', path: '/admin' },
  ];

  const renderMenuSection = (items: typeof mainMenu, title?: string) => (
    <div className="space-y-1">
      {title && (
        <h3 className="px-4 text-xs font-semibold text-gray-500 uppercase tracking-wider">
          {title}
        </h3>
      )}
      {items.map(({ icon: Icon, label, path }) => (
        <NavLink
          key={path}
          to={path}
          className={({ isActive }) =>
            `flex items-center px-4 py-2 text-sm font-medium rounded-lg ${
              isActive
                ? 'bg-blue-50 text-blue-700'
                : 'text-gray-700 hover:bg-gray-50'
            }`
          }
        >
          <Icon className="h-5 w-5 mr-3" />
          {label}
        </NavLink>
      ))}
    </div>
  );

  return (
    <div className="w-64 bg-white border-r border-gray-200 flex flex-col">
      <div className="p-6">
        <div className="flex items-center space-x-2">
          <Stethoscope className="h-8 w-8 text-blue-600" />
          <span className="text-xl font-bold text-gray-900">refer.dental</span>
        </div>
      </div>
      
      <nav className="flex-1 px-4 space-y-6 overflow-y-auto">
        {renderMenuSection(mainMenu)}
        {renderMenuSection(providerMenu, 'Directory')}
        {renderMenuSection(additionalMenu, 'Additional')}
      </nav>
      
      <div className="p-4 border-t border-gray-200">
        <div className="bg-blue-50 p-4 rounded-lg">
          <h3 className="text-sm font-medium text-blue-800">Need Help?</h3>
          <p className="mt-1 text-sm text-blue-600">
            Our support team is here for you 24/7
          </p>
          <button className="mt-3 w-full px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700">
            Contact Support
          </button>
        </div>
      </div>
    </div>
  );
}