import React, { useState } from 'react';
import { usePatientStore } from '../lib/store';
import DirectoryList from '../components/DirectoryList';
import NewPatientForm from '../components/forms/NewPatientForm';

export default function Patients() {
  const [showNewPatient, setShowNewPatient] = useState(false);
  const patients = usePatientStore((state) => state.patients);
  const addPatient = usePatientStore((state) => state.addPatient);

  const handleAdd = () => {
    setShowNewPatient(true);
  };

  const handleCreatePatient = (patientData: any) => {
    addPatient({
      ...patientData,
      id: Math.random().toString(36).substr(2, 9),
    });
    setShowNewPatient(false);
  };

  const handleUpload = (data: any[]) => {
    data.forEach(item => {
      addPatient({
        ...item,
        id: Math.random().toString(36).substr(2, 9),
      });
    });
  };

  return (
    <>
      <DirectoryList
        type="patient"
        data={patients}
        onAdd={handleAdd}
        onUpload={handleUpload}
      />
      
      {showNewPatient && (
        <NewPatientForm
          onClose={() => setShowNewPatient(false)}
          onSubmit={handleCreatePatient}
        />
      )}
    </>
  );
}