import React from 'react';
import { 
  UserPlus, 
  Users, 
  Calendar, 
  TrendingUp,
  ArrowUpRight,
  ArrowDownRight
} from 'lucide-react';
import { 
  AreaChart, 
  Area, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip,
  ResponsiveContainer 
} from 'recharts';
import StatCard from '../components/StatCard';

const data = [
  { name: 'Jan', referrals: 40 },
  { name: 'Feb', referrals: 30 },
  { name: 'Mar', referrals: 60 },
  { name: 'Apr', referrals: 45 },
  { name: 'May', referrals: 75 },
  { name: 'Jun', referrals: 65 },
];

const stats = [
  {
    title: 'Total Referrals',
    value: '315',
    change: '+12.5%',
    trend: 'up' as const,
    icon: Users,
    data: [
      { date: '2024-03-15', value: 315 },
      { date: '2024-03-14', value: 310 },
      { date: '2024-03-13', value: 305 },
      { date: '2024-03-12', value: 300 },
      { date: '2024-03-11', value: 290 },
    ]
  },
  {
    title: 'New Patients',
    value: '48',
    change: '+8.2%',
    trend: 'up' as const,
    icon: UserPlus,
    data: [
      { date: '2024-03-15', value: 48 },
      { date: '2024-03-14', value: 45 },
      { date: '2024-03-13', value: 43 },
      { date: '2024-03-12', value: 40 },
      { date: '2024-03-11', value: 38 },
    ]
  },
  {
    title: 'Appointments',
    value: '182',
    change: '-3.1%',
    trend: 'down' as const,
    icon: Calendar,
    data: [
      { date: '2024-03-15', value: 182 },
      { date: '2024-03-14', value: 185 },
      { date: '2024-03-13', value: 188 },
      { date: '2024-03-12', value: 190 },
      { date: '2024-03-11', value: 192 },
    ]
  },
  {
    title: 'Conversion Rate',
    value: '68.4%',
    change: '+5.4%',
    trend: 'up' as const,
    icon: TrendingUp,
    data: [
      { date: '2024-03-15', value: 68.4 },
      { date: '2024-03-14', value: 67.2 },
      { date: '2024-03-13', value: 66.5 },
      { date: '2024-03-12', value: 65.8 },
      { date: '2024-03-11', value: 64.9 },
    ]
  },
];

export default function Dashboard() {
  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold text-gray-900">Dashboard Overview</h1>
        <div className="flex space-x-3">
          <button className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50">
            Export
          </button>
          <button className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700">
            New Referral
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
        {stats.map((stat) => (
          <StatCard key={stat.title} {...stat} />
        ))}
      </div>

      <div className="bg-white p-6 rounded-lg border border-gray-200">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-lg font-semibold text-gray-900">Referral Trends</h2>
          <select className="text-sm border-gray-300 rounded-lg">
            <option>Last 6 months</option>
            <option>Last year</option>
            <option>All time</option>
          </select>
        </div>
        <div className="h-80">
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Area 
                type="monotone" 
                dataKey="referrals" 
                stroke="#2563eb" 
                fill="#3b82f6" 
                fillOpacity={0.1} 
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
        <div className="bg-white p-6 rounded-lg border border-gray-200">
          <h2 className="text-lg font-semibold text-gray-900 mb-4">Recent Referrals</h2>
          <div className="space-y-4">
            {[1, 2, 3].map((i) => (
              <div key={i} className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                <div className="flex items-center space-x-4">
                  <div className="h-10 w-10 rounded-full bg-blue-100 flex items-center justify-center">
                    <UserPlus className="h-5 w-5 text-blue-600" />
                  </div>
                  <div>
                    <p className="font-medium text-gray-900">Patient {i}</p>
                    <p className="text-sm text-gray-500">Referred by Dr. Smith</p>
                  </div>
                </div>
                <span className="text-sm text-gray-500">2h ago</span>
              </div>
            ))}
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg border border-gray-200">
          <h2 className="text-lg font-semibold text-gray-900 mb-4">Top Referrers</h2>
          <div className="space-y-4">
            {[1, 2, 3].map((i) => (
              <div key={i} className="flex items-center justify-between">
                <div className="flex items-center space-x-4">
                  <div className="h-10 w-10 rounded-full bg-gray-100 flex items-center justify-center">
                    <span className="font-medium text-gray-700">DS</span>
                  </div>
                  <div>
                    <p className="font-medium text-gray-900">Dr. Smith</p>
                    <p className="text-sm text-gray-500">General Dentist</p>
                  </div>
                </div>
                <div className="text-right">
                  <p className="font-medium text-gray-900">{30 - i * 5} referrals</p>
                  <p className="text-sm text-green-600">+{15 - i * 2}% this month</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}