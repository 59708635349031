import React, { useState } from 'react';
import { Search, Filter, Plus } from 'lucide-react';
import ContactViewer from './ContactViewer';
import BulkUpload from './BulkUpload';

interface DirectoryListProps {
  type: 'dentist' | 'specialist' | 'patient';
  data: any[];
  onAdd: () => void;
  onUpload: (data: any[]) => void;
}

export default function DirectoryList({ type, data, onAdd, onUpload }: DirectoryListProps) {
  const [selectedContact, setSelectedContact] = useState<any>(null);
  const [showUpload, setShowUpload] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const filteredData = data.filter(item => 
    item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleUpload = (data: any[]) => {
    onUpload(data);
    setShowUpload(false);
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold text-gray-900">
          {type.charAt(0).toUpperCase() + type.slice(1)} Directory
        </h1>
        <div className="flex space-x-3">
          <button 
            onClick={() => setShowUpload(true)}
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
          >
            <Filter className="h-4 w-4 mr-2" />
            Bulk Upload
          </button>
          <button 
            onClick={onAdd}
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700"
          >
            <Plus className="h-4 w-4 mr-2" />
            Add {type.charAt(0).toUpperCase() + type.slice(1)}
          </button>
        </div>
      </div>

      <div className="relative">
        <input
          type="text"
          placeholder={`Search ${type}s...`}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredData.map((item) => (
          <div
            key={item.id}
            onClick={() => setSelectedContact(item)}
            className="bg-white p-6 rounded-lg border border-gray-200 hover:shadow-md transition-shadow cursor-pointer"
          >
            <div className="flex items-center space-x-4">
              <div className="h-12 w-12 rounded-full bg-blue-100 flex items-center justify-center">
                <span className="text-lg font-medium text-blue-600">
                  {item.name.split(' ').map((n: string) => n[0]).join('')}
                </span>
              </div>
              <div>
                <h3 className="text-lg font-medium text-gray-900">{item.name}</h3>
                <p className="text-sm text-gray-500">{item.email}</p>
              </div>
            </div>
            <div className="mt-4 text-sm text-gray-500">
              {type === 'patient' ? (
                <>DOB: {item.dateOfBirth}</>
              ) : (
                <>{item.practice}</>
              )}
            </div>
          </div>
        ))}
      </div>

      {selectedContact && (
        <ContactViewer
          type={type}
          data={selectedContact}
          onClose={() => setSelectedContact(null)}
        />
      )}

      {showUpload && (
        <BulkUpload
          type={`${type}s`}
          onUpload={handleUpload}
          onClose={() => setShowUpload(false)}
        />
      )}
    </div>
  );
}