import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import App from './App.tsx';
import Login from './pages/Login';
import Admin from './pages/Admin';
import ProtectedRoute from './components/ProtectedRoute';
import './index.css';

import Dashboard from './pages/Dashboard';
import Specialists from './pages/Specialists';
import Dentists from './pages/Dentists';
import Patients from './pages/Patients';
import Communications from './pages/Communications';
import Education from './pages/Education';
import Schedule from './pages/Schedule';
import Documents from './pages/Documents';
import Rewards from './pages/Rewards';
import Forum from './pages/Forum';
import Settings from './pages/Settings';

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <App />
            </ProtectedRoute>
          }
        >
          <Route index element={<Dashboard />} />
          <Route path="specialists" element={<Specialists />} />
          <Route path="dentists" element={<Dentists />} />
          <Route path="patients" element={<Patients />} />
          <Route path="communications" element={<Communications />} />
          <Route path="education" element={<Education />} />
          <Route path="schedule" element={<Schedule />} />
          <Route path="documents" element={<Documents />} />
          <Route path="rewards" element={<Rewards />} />
          <Route path="forum" element={<Forum />} />
          <Route path="admin" element={<Admin />} />
          <Route path="settings" element={<Settings />} />
        </Route>
      </Routes>
    </Router>
  </StrictMode>
);