import React, { useState } from 'react';
import { QrCode, Nfc, ArrowRight } from 'lucide-react';
import { useProviderStore } from '../lib/store';

export default function QuickReferral() {
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const providers = useProviderStore((state) => state.providers);

  const handleQuickReferral = async (e: React.FormEvent) => {
    e.preventDefault();
    const provider = providers.find(p => p.id === code);
    
    if (provider) {
      window.location.href = `/referrals/new?provider=${provider.id}`;
    } else {
      setError('Provider not found. Please check the code and try again.');
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h3 className="text-lg font-medium text-gray-900 mb-4">Quick Referral</h3>
      <div className="flex items-center space-x-4 mb-4">
        <button className="flex-1 flex items-center justify-center p-3 border border-gray-300 rounded-md hover:bg-gray-50">
          <QrCode className="h-6 w-6 text-blue-600" />
          <span className="ml-2 text-sm font-medium text-gray-700">Scan QR</span>
        </button>
        <button className="flex-1 flex items-center justify-center p-3 border border-gray-300 rounded-md hover:bg-gray-50">
          <Nfc className="h-6 w-6 text-blue-600" />
          <span className="ml-2 text-sm font-medium text-gray-700">Tap NFC</span>
        </button>
      </div>
      
      <div className="relative">
        <div className="absolute inset-0 flex items-center">
          <div className="w-full border-t border-gray-300"></div>
        </div>
        <div className="relative flex justify-center text-sm">
          <span className="px-2 bg-white text-gray-500">or enter code</span>
        </div>
      </div>

      <form onSubmit={handleQuickReferral} className="mt-4">
        <div className="flex space-x-2">
          <input
            type="text"
            maxLength={5}
            value={code}
            onChange={(e) => setCode(e.target.value.toUpperCase())}
            placeholder="Enter 5-digit code"
            className="flex-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-center text-lg tracking-widest"
          />
          <button
            type="submit"
            disabled={code.length !== 5}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <ArrowRight className="h-5 w-5" />
          </button>
        </div>
        {error && (
          <p className="mt-2 text-sm text-red-600">{error}</p>
        )}
      </form>
    </div>
  );
}