import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface AuthState {
  user: User | null;
  setUser: (user: User | null) => void;
}

interface ProviderState {
  providers: Provider[];
  addProvider: (provider: Provider) => void;
  updateProvider: (id: string, provider: Partial<Provider>) => void;
  removeProvider: (id: string) => void;
}

interface PatientState {
  patients: Patient[];
  addPatient: (patient: Patient) => void;
  updatePatient: (id: string, patient: Partial<Patient>) => void;
  removePatient: (id: string) => void;
}

interface ForumState {
  posts: ForumPost[];
  comments: ForumComment[];
  addPost: (post: ForumPost) => void;
  addComment: (comment: ForumComment) => void;
  likePost: (postId: string, userId: string) => void;
  unlikePost: (postId: string, userId: string) => void;
}

interface ChatState {
  messages: ChatMessage[];
  addMessage: (message: ChatMessage) => void;
  markAsRead: (messageId: string) => void;
}

interface AlertState {
  alerts: Alert[];
  addAlert: (alert: Alert) => void;
  markAsRead: (alertId: string) => void;
  markAllAsRead: () => void;
}

export const useAuthStore = create<AuthState>()(
  persist(
    (set) => ({
      user: null,
      setUser: (user) => set({ user }),
    }),
    {
      name: 'auth-storage',
    }
  )
);

export const useProviderStore = create<ProviderState>()(
  persist(
    (set) => ({
      providers: [],
      addProvider: (provider) => 
        set((state) => ({ 
          providers: [...state.providers, { ...provider, id: crypto.randomUUID() }] 
        })),
      updateProvider: (id, provider) =>
        set((state) => ({
          providers: state.providers.map((p) => 
            p.id === id ? { ...p, ...provider } : p
          ),
        })),
      removeProvider: (id) =>
        set((state) => ({
          providers: state.providers.filter((p) => p.id !== id),
        })),
    }),
    {
      name: 'providers-storage',
    }
  )
);

export const usePatientStore = create<PatientState>()(
  persist(
    (set) => ({
      patients: [],
      addPatient: (patient) =>
        set((state) => ({
          patients: [...state.patients, { ...patient, id: crypto.randomUUID() }],
        })),
      updatePatient: (id, patient) =>
        set((state) => ({
          patients: state.patients.map((p) =>
            p.id === id ? { ...p, ...patient } : p
          ),
        })),
      removePatient: (id) =>
        set((state) => ({
          patients: state.patients.filter((p) => p.id !== id),
        })),
    }),
    {
      name: 'patients-storage',
    }
  )
);

export const useForumStore = create<ForumState>()(
  persist(
    (set) => ({
      posts: [],
      comments: [],
      addPost: (post) => 
        set((state) => ({ 
          posts: [{ ...post, id: crypto.randomUUID() }, ...state.posts] 
        })),
      addComment: (comment) => 
        set((state) => ({
          comments: [...state.comments, { ...comment, id: crypto.randomUUID() }],
          posts: state.posts.map(post =>
            post.id === comment.postId
              ? { ...post, comments: (post.comments || 0) + 1 }
              : post
          ),
        })),
      likePost: (postId, userId) =>
        set((state) => ({
          posts: state.posts.map(post =>
            post.id === postId
              ? { ...post, likes: [...(post.likes || []), userId] }
              : post
          ),
        })),
      unlikePost: (postId, userId) =>
        set((state) => ({
          posts: state.posts.map(post =>
            post.id === postId
              ? { ...post, likes: (post.likes || []).filter(id => id !== userId) }
              : post
          ),
        })),
    }),
    {
      name: 'forum-storage',
    }
  )
);

export const useChatStore = create<ChatState>()(
  persist(
    (set) => ({
      messages: [],
      addMessage: (message) =>
        set((state) => ({
          messages: [...state.messages, { ...message, id: crypto.randomUUID() }],
        })),
      markAsRead: (messageId) =>
        set((state) => ({
          messages: state.messages.map((m) =>
            m.id === messageId ? { ...m, read: true } : m
          ),
        })),
    }),
    {
      name: 'chat-storage',
    }
  )
);

export const useAlertStore = create<AlertState>()(
  persist(
    (set) => ({
      alerts: [],
      addAlert: (alert) =>
        set((state) => ({
          alerts: [...state.alerts, { ...alert, id: crypto.randomUUID() }],
        })),
      markAsRead: (alertId) =>
        set((state) => ({
          alerts: state.alerts.map((a) =>
            a.id === alertId ? { ...a, read: true } : a
          ),
        })),
      markAllAsRead: () =>
        set((state) => ({
          alerts: state.alerts.map((a) => ({ ...a, read: true })),
        })),
    }),
    {
      name: 'alerts-storage',
    }
  )
);