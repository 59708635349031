import React, { useState } from 'react';
import { MessageCircle, ThumbsUp, MessageSquare, Share2, X } from 'lucide-react';
import { useForumStore, useAuthStore } from '../lib/store';
import { format } from 'date-fns';

interface NewPostFormData {
  title: string;
  content: string;
  tags: string[];
}

export default function Forum() {
  const user = useAuthStore((state) => state.user);
  const { posts, comments, addPost, addComment, likePost, unlikePost } = useForumStore();
  const [showNewPost, setShowNewPost] = useState(false);
  const [newPost, setNewPost] = useState<NewPostFormData>({
    title: '',
    content: '',
    tags: []
  });
  const [selectedPost, setSelectedPost] = useState<string | null>(null);
  const [newComment, setNewComment] = useState('');

  const handleCreatePost = (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) return;

    const post: ForumPost = {
      id: Math.random().toString(36).substring(7),
      author: {
        id: user.id,
        name: user.name,
        role: user.role
      },
      title: newPost.title,
      content: newPost.content,
      date: new Date().toISOString(),
      likes: [],
      comments: 0,
      tags: newPost.tags
    };

    addPost(post);
    setNewPost({ title: '', content: '', tags: [] });
    setShowNewPost(false);
  };

  const handleAddComment = (postId: string) => {
    if (!user || !newComment.trim()) return;

    const comment: ForumComment = {
      id: Math.random().toString(36).substring(7),
      postId,
      author: {
        id: user.id,
        name: user.name,
        role: user.role
      },
      content: newComment,
      date: new Date().toISOString()
    };

    addComment(comment);
    setNewComment('');
  };

  const handleLikePost = (postId: string) => {
    if (!user) return;
    
    const post = posts.find(p => p.id === postId);
    if (!post) return;

    if (post.likes.includes(user.id)) {
      unlikePost(postId);
    } else {
      likePost(postId);
    }
  };

  const postComments = (postId: string) => 
    comments.filter(comment => comment.postId === postId);

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold text-gray-900">Community Forum</h1>
        <button
          onClick={() => setShowNewPost(true)}
          className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700"
        >
          <MessageCircle className="h-4 w-4 mr-2" />
          New Post
        </button>
      </div>

      {showNewPost && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg shadow-xl max-w-2xl w-full">
            <div className="p-6">
              <div className="flex items-center justify-between mb-4">
                <h2 className="text-xl font-semibold text-gray-900">Create New Post</h2>
                <button onClick={() => setShowNewPost(false)} className="text-gray-400 hover:text-gray-500">
                  <X className="h-6 w-6" />
                </button>
              </div>

              <form onSubmit={handleCreatePost} className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">Title</label>
                  <input
                    type="text"
                    value={newPost.title}
                    onChange={(e) => setNewPost({ ...newPost, title: e.target.value })}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Content</label>
                  <textarea
                    value={newPost.content}
                    onChange={(e) => setNewPost({ ...newPost, content: e.target.value })}
                    rows={4}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Tags</label>
                  <input
                    type="text"
                    placeholder="Separate tags with commas"
                    onChange={(e) => setNewPost({ 
                      ...newPost, 
                      tags: e.target.value.split(',').map(tag => tag.trim()) 
                    })}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  />
                </div>
                <div className="flex justify-end space-x-3">
                  <button
                    type="button"
                    onClick={() => setShowNewPost(false)}
                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700"
                  >
                    Post
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      <div className="space-y-6">
        {posts.map((post) => (
          <div key={post.id} className="bg-white p-6 rounded-lg border border-gray-200">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center space-x-3">
                <div className="h-10 w-10 rounded-full bg-blue-100 flex items-center justify-center">
                  <span className="text-sm font-medium text-blue-600">
                    {post.author.name.split(' ').map(n => n[0]).join('')}
                  </span>
                </div>
                <div>
                  <h3 className="text-lg font-medium text-gray-900">{post.title}</h3>
                  <p className="text-sm text-gray-500">
                    Posted by {post.author.name} ({post.author.role}) on{' '}
                    {format(new Date(post.date), 'MMM d, yyyy')}
                  </p>
                </div>
              </div>
              <div className="flex space-x-2">
                {post.tags.map((tag) => (
                  <span
                    key={tag}
                    className="px-2 py-1 text-xs font-medium text-blue-600 bg-blue-100 rounded-full"
                  >
                    {tag}
                  </span>
                ))}
              </div>
            </div>
            <p className="text-gray-600 mb-4">{post.content}</p>
            <div className="flex items-center space-x-4 text-gray-500">
              <button 
                onClick={() => handleLikePost(post.id)}
                className={`flex items-center space-x-1 ${
                  post.likes.includes(user?.id || '') ? 'text-blue-600' : 'hover:text-blue-600'
                }`}
              >
                <ThumbsUp className="h-4 w-4" />
                <span>{post.likes.length}</span>
              </button>
              <button 
                onClick={() => setSelectedPost(selectedPost === post.id ? null : post.id)}
                className="flex items-center space-x-1 hover:text-blue-600"
              >
                <MessageSquare className="h-4 w-4" />
                <span>{post.comments}</span>
              </button>
              <button className="flex items-center space-x-1 hover:text-blue-600">
                <Share2 className="h-4 w-4" />
                <span>Share</span>
              </button>
            </div>

            {selectedPost === post.id && (
              <div className="mt-4 pt-4 border-t border-gray-200">
                <div className="space-y-4">
                  {postComments(post.id).map((comment) => (
                    <div key={comment.id} className="flex space-x-3">
                      <div className="flex-shrink-0">
                        <div className="h-8 w-8 rounded-full bg-gray-100 flex items-center justify-center">
                          <span className="text-xs font-medium text-gray-600">
                            {comment.author.name.split(' ').map(n => n[0]).join('')}
                          </span>
                        </div>
                      </div>
                      <div className="flex-1">
                        <div className="text-sm">
                          <span className="font-medium text-gray-900">{comment.author.name}</span>
                          <span className="text-gray-500"> • </span>
                          <span className="text-gray-500">
                            {format(new Date(comment.date), 'MMM d, yyyy')}
                          </span>
                        </div>
                        <p className="mt-1 text-sm text-gray-600">{comment.content}</p>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="mt-4 flex space-x-3">
                  <input
                    type="text"
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                    placeholder="Add a comment..."
                    className="flex-1 rounded-lg border-gray-300 focus:border-blue-500 focus:ring-blue-500"
                  />
                  <button
                    onClick={() => handleAddComment(post.id)}
                    disabled={!newComment.trim()}
                    className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 disabled:opacity-50"
                  >
                    Comment
                  </button>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}