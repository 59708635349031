import React, { useState } from 'react';
import { useProviderStore } from '../lib/store';
import DirectoryList from '../components/DirectoryList';
import NewProviderForm from '../components/forms/NewProviderForm';

export default function Dentists() {
  const [showNewProvider, setShowNewProvider] = useState(false);
  const providers = useProviderStore((state) => 
    state.providers.filter(p => p.specialty === 'General Dentistry')
  );
  const addProvider = useProviderStore((state) => state.addProvider);

  const handleAdd = () => {
    setShowNewProvider(true);
  };

  const handleCreateProvider = (providerData: any) => {
    addProvider({
      ...providerData,
      id: Math.random().toString(36).substr(2, 9),
      specialty: 'General Dentistry',
    });
    setShowNewProvider(false);
  };

  const handleUpload = (data: any[]) => {
    data.forEach(item => {
      addProvider({
        ...item,
        id: Math.random().toString(36).substr(2, 9),
        specialty: 'General Dentistry',
      });
    });
  };

  return (
    <>
      <DirectoryList
        type="dentist"
        data={providers}
        onAdd={handleAdd}
        onUpload={handleUpload}
      />
      
      {showNewProvider && (
        <NewProviderForm
          type="dentist"
          onClose={() => setShowNewProvider(false)}
          onSubmit={handleCreateProvider}
        />
      )}
    </>
  );
}